#homepage-background {/* TODO: rename to be less generic */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 30%;

    user-select: none;
    pointer-events: none;

    z-index: -1;
}

.content {
    padding: 32px;
    flex-grow: 1;
    max-width: 100%;
    margin: 0 auto;;
}

.content.--centered {
    display: flex;
    align-items: center;
}

.profile-picture {
    margin-bottom: 24px;
    max-width: 60%;

    margin-left: auto;
    margin-right: auto;
}

.centered {
    text-align: center;
}

.links {
    color: #0bb4aa;

}

.links:hover {
    color: #09766f;
}

button {
    background-color: #0bb4aa; /* Background color */
    color: white; /* Text color */
    padding: 12px 24px; /* Padding */
    border: none; /* Remove borders */
    border-radius: 25px; /* Rounded edges */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Add cursor on hover */
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25); /* Add shadow */
    margin-bottom: 0;
  }
  
  /* Hover state */
button:hover {
    background-color: #09766f;
  }
  