.swiper-container {
  flex-grow: 1;
  width: 70%;
  height: auto;
  margin: 0 auto;
  padding-top: 10px;
  min-width: 50%;
}

.swiper-wrapper {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  transition-property: transform;
  max-width: 100%;
  padding-bottom: 0%;
  margin-bottom: 0%;
}

.swiper-slide {
  display: flex;
  flex-direction: row;
  padding: 20px;
  max-width: 100%;
  align-items: center;
  opacity: 60%;
}

.swiper-slide-active {
  opacity: 100%;
}

.swiper-slide img {
  max-width: 100%;
  margin: auto;
  padding-bottom: 20px;
  justify-content: center;
}

.slide-row {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.image-container {
  flex: 4;
  margin-right: 20px;
}

.borderright {
  margin-right: 20px;
  border-right: 5px solid whitesmoke;
  margin-top: 3%;
  margin-bottom: 3%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  align-self: stretch;
  
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1.5;
  text-align: left;
  margin-right: 2%;
}

.content-container h3 {
  color: #0bb4aa;
  text-align: left;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
}

/* Hover state */
.content-container h3:hover {
  color: #09766f;
}

.content-container p {
  text-align: left;
  margin-bottom: 20px;
  font-size: 18px;
}

.swiper-pagination {
  display: block;
  z-index:auto;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}

.swiper-pagination-bullet {
  background-color: whitesmoke !important;
}

.swiper-slide-shadow-left {
  display: none;
}

.swiper-slide-shadow-right {
  display: none;
}

.page-parent-content h1, .page-content h1{
  padding-top: 5%;
  display: inline-block;
  white-space: wrap;
}

.portfolio-container {
  margin-left: 5%;
  margin-right: 5%;

}


.projects-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between; 
  align-items: stretch;
}

.project-card {
  color: black;
  flex: 0 0 48%; 
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
  box-sizing: border-box;
  background-color: #FAFAFA;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-card img {
  width: 50%;
  height: auto;
  object-fit: contain;
  margin: auto;
}

.project-card h3 {
  margin-top: 15px;
}

.project-card p {
  margin: 5px 0;
}

@media (max-width: 767px) {
  .project-card {
    flex: 0 0 100%;
  }
}


@media only screen and (max-width: 1000px) {
  .swiper-container {
    max-width: 100%;
  }

  .slide-row {
    flex-direction: column;
  }

  .borderright {
    display: none;
  }
  
  .content-container {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
  }

  .image-container {
    margin-right: 0px;
  }
  .content-container p, .content-container h3, h1 {
    text-align: center;
  }

  .swiper-wrapper {
    max-width: 100%;
  }

  .centered{
    max-width: 100%;
  }
}

@media (min-width: 1000px) {
  .page-parent-content h1 {
    align-items: top;
    padding-bottom: 5%;
  }
}

@media (min-width: 1900px) {
  body {
    font-size: 20px;
  }
  .container {
    max-width: 1600px;
    margin: 0 auto;
  }

  .content-container h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  .content-container p {
    font-size: 24px;
    margin-bottom: 40px;
    width: 75%;
  }

  .content-container {
    margin-right: 0%;
  }

}

@media (min-width: 3000px) {
  .content-container p {
    width: 50%;
  }
}