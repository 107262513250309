* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;

    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    --webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

body {
    background: var(--color-bg);
    color: white;
    overflow-x: hidden;
}

a {
    color: unset;
    text-decoration: none;
}

img {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

#page-wrapper {
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#page-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

#page-content-basic {
    flex-grow: 1;
}


#page-parent-content {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mb {
    margin-bottom: 16px;
}

.mb--light {
    margin-bottom: 8px;
}


.mb--heavy {
    margin-bottom: 32px;
}

.mt {
    margin-top: 16px;
}

.mt--light {
    margin-top: 8px;
}


.mt--heavy {
    margin-top: 32px!important;
}

.mt--super-heavy {
    margin-top: 64px!important;
}

body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

/* webkit-based browsers (e.g. Chrome, Safari) */
body::-webkit-scrollbar {
    width: 0.5em;
    display: None;
  }
  